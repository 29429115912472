<template>
  <div class="page-container md-layout-column">
    <div>
      <div class="container-fluid size my-progress">
        <h2 class="mb-0 title-favorites">mis cursos</h2>

        <div v-if="isLoading">
          <div class="row mt-3">
            <skeleton-card-video
              v-for="index in 3"
              :key="index"
              class="col-md-4 col-lg-4 col-sm-6 mb-3"
            />
          </div>
        </div>
        <div v-else>
          <div v-if="this.getUserCourses">
            <p
              class="favorite-description text-center my-3 font-weight-bold pb-3 user__courses__title__text__black"
            >
              Aquí encontrarás todos tus cursos disponibles
            </p>
            <div v-if="this.getUserCourses.length" class="row mb-4 mx-auto">
              <div
                v-for="data in this.getUserCourses"
                :key="data.id"
                class="col-md-4 col-lg-4 col-sm-6 mb-2"
              >
                <card-course :data="data" class="card-course-h-w" />
              </div>
            </div>
            <div v-else>
              <h3 class="text-center font-weight-bold">
                No tienes cursos disponibles
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { USER_COURSES } from '../../../store/actions/user';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo';
import CardCourse from '../Courses/components/CardCourse';
import { mapGetters } from 'vuex';
export default {
  name: 'UserCoursesView',
  components: {
    SkeletonCardVideo,
    CardCourse,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['getUserCourses']),
  },
  mounted() {
    this.$store.dispatch(USER_COURSES).then(() => (this.isLoading = false));
  },
};
</script>

<style scoped>
.user__courses__title__text__black {
  color: #000;
}

.card-course-h-w {
  width: 100%;
  height: 300px;
}
</style>
