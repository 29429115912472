<template>
  <div v-if="!isLoading">
    <div>
      <div class="row">
        <div class="col-12 px-0">
          <div class="img-cover-instructor">
            <img
              alt=""
              class="w-100 img-course-background-image"
              :src="
                getCurrentCourse.poster
                  ? getCurrentCourse.poster
                  : require('@/assets/images/logo/placeholder.png')
              "
              style="background: #f7f7f7"
            />
          </div>
          <div class="img-course-cover-profile"></div>
        </div>
      </div>
      <div class="container-fluid size pr-0">
        <h1 class="my-3 text-description-course">
          {{ getCurrentCourse.name }}
        </h1>
        <div class="d-flex justify-content-center orientation-btn-mobile">
          <button
            class="btn rounded-0 btn-maquillate btn-descript-course"
            style="padding: 11px 25px"
          >
            Entrar al curso
          </button>
        </div>
        <div class="my-5 tabs-description-course style-config">
          <div class="row justify-content-between mr-0 margin-mobile-container">
            <div class="col-md-5">
              <h1 class="mt-3">DESCRIPCIÓN</h1>
              <p
                class="margin-top-description"
                style="color: black"
                v-html="getCurrentCourse.content"
              ></p>
            </div>
            <div class="col-md-6 pr-0 pl-0 embed-container">
              <video-player
                ref="previewplayer"
                :options="videoOptions"
                class="videoplayer mt-4"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 container-skills">
        <div class="container-fluid size container-learning">
          <h1 class="mb-4">¿Qué aprenderás en este curso?</h1>

          <div class="row" style="padding: 0 100px">
            <div
              v-for="{ id, bullet } in getCurrentCourse.bullets"
              :key="id"
              class="col-md-6"
            >
              <div class="d-flex">
                <i class="fas fa-check pr-2 pt-2"></i>
                <p class="mb-2">{{ bullet }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 padding-mobile-container mt-0 px-0"
        style="background: #fcfcfc"
      >
        <div class="container-fluid size d-flex py-4">
          <div class="col-md-6 border-right-desk">
            <div class="d-flex container-info-instructor-course-descript">
              <img
                :src="
                  getCurrentCourse.instructors.length
                    ? getCurrentCourse.instructors[0].avatar.url
                    : ''
                "
                alt="instructor"
                class="img-instructor-description"
              />
              <div class="d-flex flex-column justify-content-center">
                <h3>PERFIL DEL INSTRUCTOR</h3>
                <h1 v-if="getCurrentCourse.instructors.length" class="mb-0">
                  {{
                    `${getCurrentCourse.instructors[0].firstname} ${getCurrentCourse.instructors[0].lastname}`
                  }}
                </h1>
              </div>
            </div>
            <div :class="['mt-4 see-more', isError ? 'see-less' : 'see-more']">
              <p
                v-if="getCurrentCourse.instructors.length > 0"
                class="mb-0"
                style="color: black; white-space: break-spaces"
              >
                {{ getCurrentCourse.instructors[0].bio }}
              </p>
            </div>
            <div
              :class="['details-see-more', isError ? 'details-see-less' : ' ']"
              @click="isError = !isError"
            >
              <i
                v-if="!isError"
                class="fas fa-chevron-down"
                style="color: #b7227e; font-size: 28px"
              ></i>
              <i
                v-else
                class="fas fa-chevron-up"
                style="color: #b7227e; font-size: 28px"
              ></i>
            </div>
          </div>
          <div class="col-md-3 border-right-desk px-4">
            <h4>TIEMPO TOTAL DEL CURSO</h4>
            <p style="color: black">
              {{ getCourseDuration(getCurrentCourseVideos) }}
            </p>
            <h4>CLASES</h4>
            <p style="color: black">
              {{ getCurrentCourseVideos.length }} clases
            </p>
          </div>
          <div class="col-md-3 px-4">
            <h4>ZONA ENFOCADA</h4>
            <p
              v-for="category in getCurrentCourse.categories"
              :key="category.id"
              style="color: black"
            >
              {{ category.title }}
            </p>
            <!-- <p>Cejas</p>
          <p>Labios</p> -->
          </div>
        </div>
      </div>
      <div
        class="col-12 padding-mobile-container px-0 section-courses-payments py-4"
        style="background: #b5006f"
      >
        <div class="container-fluid size">
          <div
            v-for="(courseVideo, index) in getCurrentCourseVideos"
            :key="index"
            class="container-fluid d-flex py-4 border-bottom px-0"
          >
            <div class="col-1 pl-0">
              <h1>{{ index + 1 }}</h1>
            </div>
            <div class="col-md-11">
              <h1 class="text-uppercase">
                {{ courseVideo.title }}
              </h1>
              <h2>
                <span
                  class="d-inline-block mr-2 text-white-desc"
                  v-html="
                    courseVideo.content
                      ? courseVideo.content
                      : 'Sin descripcion'
                  "
                ></span>
              </h2>
              <h2>
                <span class="time-courses">{{ formatTime(courseVideo) }}</span>
              </h2>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading-panel v-else title="Cargando vista previa..."></loading-panel>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingPanel from '../../../components/LoadingPanel';
import VideoPlayer from '../../../components/VideoPlayer';
import 'video.js/dist/video-js.css';
import { FETCH_COURSE } from '../../../store/actions/course';
import config from '@/config/config';
import format from 'format-duration';

export default {
  name: 'AdminCoursePreview',
  components: { LoadingPanel, VideoPlayer },
  data() {
    return {
      isLoading: true,
      isError: null,
      videoOptions: {},
      userCourse: false,
    };
  },
  computed: {
    ...mapGetters(['getCurrentCourse', 'getCurrentCourseVideos']),
  },
  watch: {
    getCurrentCourse: function (newValue) {
      console.log('newValue', newValue);
      if (newValue) {
        this.videoOptions = {
          autoplay: false,
          controls: true,
          sources: [
            {
              src: newValue.trailer ? newValue.trailer.url : '',
              type: 'application/x-mpegURL',
            },
          ],
          poster: newValue.trailer_poster ? newValue.trailer_poster : '',
          fluid: true,
          aspectRatio: '16:9',
          hls: true,
          plugins: {
            airPlay: {
              addButtonToControlBar: true, // defaults to `true`
            },
          },
        };
      } else {
        this.videoOptions = {};
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchCourse();
  },
  methods: {
    async fetchCourse() {
      await this.$store.dispatch(FETCH_COURSE, this.$route.params.id);
    },
    formatTime(courseVideo) {
      const duration = courseVideo.video ? courseVideo.video.duration : 0;

      return format(duration * 1000);
    },
    getCourseVideoThumbnail(courseVideo) {
      if (!courseVideo.video)
        return `${config.data.apiUrlLA}/img/theme/profileDefault.jpg`;
      return courseVideo.video.thumbnail;
    },
    getInstructorImage(instructors = []) {
      if (!instructors.length)
        return `${config.data.apiUrlLA}/img/theme/profileDefault.jpg`;
      return instructors[0].thumbnail_profile;
    },
    getCourseDuration(courseVideos = []) {
      const duration = courseVideos.reduce((acc, curr) => {
        const _duration = curr.video ? parseInt(curr.video.duration) : 0;
        return acc + _duration;
      }, 0);

      return format(duration * 1000);
    },
    getInstructors(instructors) {
      if (!instructors.length) return 'Sin instructores asignados';

      return instructors
        .map((instructor) => `${instructor.firstname} ${instructor.lastname}`)
        .join(' , ');
    },
  },
};
</script>

<style>

.img-cover-instructor,
.img-cover-instructor img {
  height: 100%;
  max-height: auto;
  object-fit: cover;
}

.img-course-cover-profile {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(70%, rgba(0, 0, 0, 0.75))
  )  !important;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.75) 70%);
  width: 100%;
  position: absolute;
  height: 100%;
  max-height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.text-white-desc span, .text-white-desc strong
{
  color: white !important;

}
.text-white-desc
{
  font-size: 1rem !important;
}


.time-courses
{
  font-size: 1rem !important;
  margin-left: 0px !important;
  color: rgba(255, 255, 255, 0.822) !important
}

li, li strong
{
  font-size: 1rem !important;
}


</style>
