<template>
  <div class="row">
    <div class="col-sm-12">
      <h1 class="allClasses__subtitles mobile-space m-0">Todos los Cursos</h1>
      <div v-if="isLoading" class="row mt-4">
        <skeleton-card-video
          v-for="index in 3"
          :key="index"
          class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"
        />
      </div>
      <div v-else class="row mt-4">
        <card-course
          v-for="course in getPublicCourses.items"
          :key="course.id"
          :data="course"
          class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 pb-2 mb-2"
        />
      </div>
      <div v-if="getPublicCourses.items && getPublicCourses.items.length">
        <div
          v-if="totalPages > 1"
          class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
        >
          <base-pagination
            :value="page"
            class="pagination-style"
            :page-count="totalPages"
            @input="paginationChange"
          ></base-pagination>
        </div>
      </div>
      <div v-else class="text-center">
        <h3>No hay contenido disponible</h3>
      </div>
    </div>
  </div>
</template>
<script>
import CardCourse from './components/CardCourse.vue';
import SkeletonCardVideo from '../../Components/skeletons/SkeletonCardVideo.vue';
import { FETCH_PUBLIC_COURSES } from '../../../store/actions/public';
import { mapGetters } from 'vuex';

export default {
  name: 'CoursesView',
  components: {
    SkeletonCardVideo,
    CardCourse,
  },

  data() {
    return {
      isLoading: true,
      page: 1,
      perPage: 30,
      slug:'test',
    };
  },
  metaInfo() {
    const slug = this.$route.params.slug;
    console.log('test');
    const category = this.getPublicCategories.find((publicCategory) => publicCategory.slug === slug);
    return {
      title: category ? `${category.title.charAt(0).toUpperCase()}${category.title.slice(1)} - Maquillate.com` : 'Explorar Cursos - Maquillate.com'
    }
  },
  computed: {
    ...mapGetters(['getPublicCourses','getPublicCategories']),
    totalPages() {
      return Math.ceil(this.getPublicCourses.total / this.perPage);
    },
  },
  watch: {
    $route: async function (to) {
      const slug = to.params.slug;
      this.isLoading = true;
      this.page = 1;
      this.fetchCourses({
        category_slug: slug,
        per_page: this.perPage,
        page: 1,
      });
    },
  },
  mounted() {
    this.fetchCourses();
  },
  methods: {
    fetchCourses(
      filter = {
        category_slug: this.$route.params.slug,
        per_page: this.perPage,
        page: this.page,
      },
    ) {
      this.$store
        .dispatch(FETCH_PUBLIC_COURSES, filter)
        .then(() => (this.isLoading = false))
        .catch(() => {
          this.isLoading = false;
          this.notify('danger', 'Tenemos problemas para cargar los cursos');
        });
    },
    paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      this.fetchCourses({
        category_slug: this.$route.params.slug,
        per_page: this.perPage,
        page,
      });
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>
